<template>
  <div>
    <div class="modal" id="edit-student-info-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Student Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">Name (Last Name, First Name, Middle Name)</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-uppercase"
                  placeholder="Last Name"
                  v-model="form.lastName"
                />
                <input
                  type="text"
                  class="form-control text-uppercase"
                  placeholder="First Name"
                  v-model="form.firstName"
                />
                <input
                  type="text"
                  class="form-control text-uppercase"
                  placeholder="Middle Name "
                  v-model="form.middleName"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-8">
                <div class="form-group">
                  <label for="">Semester & Academic Year Started</label>
                  <div class="input-group">
                    <select
                      name=""
                      id=""
                      class="form-control  "
                      v-model="form.semEnrolled"
                      required
                    >
                      <option value="1ST SEMESTER">1ST SEMESTER</option>
                      <option value="2ND SEMESTER">2ND SEMESTER</option>
                      <option value="3RD SEMESTER">3RD SEMESTER</option>
                      <option value="SUMMER">SUMMER</option>
                      <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                      <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                      <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                    </select>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="form.acadYear"
                    >
                      <option value="2022-2023">2022-2023</option>
                      <option value="2021-2022">2021-2022</option>
                      <option value="2020-2021">2020-2021</option>
                      <option value="2019-2020">2019-2020</option>
                      <option value="2018-2019">2018-2019</option>
                      <option value="2017-2018">2017-2018</option>
                      <option value="2016-2017">2016-2017</option>
                      <option value="2015-2016">2015-2016</option>
                      <option value="2014-2015">2014-2015</option>
                      <option value="2013-2014">2013-2014</option>
                      <option value="2012-2013">2012-2013</option>
                      <option value="2011-2012">2011-2012</option>
                      <option value="2010-2011">2010-2011</option>
                      <option value="2009-2010">2009-2010</option>
                      <option value="2008-2009">2008-2009</option>
                      <option value="2007-2008">2007-2008</option>
                      <option value="2006-2007">2006-2007</option>
                      <option value="2005-2006">2005-2006</option>
                      <option value="2004-2005">2004-2005</option>
                      <option value="2003-2004">2003-2004</option>
                      <option value="2002-2003">2002-2003</option>
                      <option value="2001-2002">2001-2002</option>
                      <option value="2000-2001">2000-2001</option>
                      <option value="1999-2000">1999-2000</option>
                      <option value="1998-1999">1998-1999</option>
                      <option value="1997-1998">1997-1998</option>
                      <option value="1996-1997">1996-1997</option>
                      <option value="1995-1996">1995-1996</option>
                      <option value="1994-1995">1994-1995</option>
                      <option value="1993-1994">1993-1994</option>
                      <option value="1992-1993">1992-1993</option>
                      <option value="1991-1992">1991-1992</option>
                      <option value="1990-1991">1990-1991</option>
                      <option value="1989-1990">1989-1990</option>
                      <option value="1988-1989">1988-1989</option>
                      <option value="1987-1988">1987-1988</option>
                      <option value="1986-1987">1986-1987</option>
                      <option value="1985-1986">1985-1986</option>
                      <option value="1984-1985">1984-1985</option>
                      <option value="1983-1984">1983-1984</option>
                      <option value="1982-1983">1982-1983</option>
                      <option value="1981-1982">1981-1982</option>
                      <option value="1980-1981">1980-1981</option>
                      <option value="1979-1980">1979-1980</option>
                      <option value="1978-1979">1978-1979</option>
                      <option value="1977-1978">1977-1978</option>
                      <option value="1976-1977">1976-1977</option>
                      <option value="1975-1976">1975-1976</option>
                      <option value="1974-1975">1974-1975</option>
                      <option value="1973-1974">1973-1974</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <label for="">Sex</label>
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="form.gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              id="edit-student-info-btn"
              @click="editStudentInfo"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditStudentInfoModal',
  data() {
    return {
      acadYears: [],
      semesters: [],
      form: new Form({
        id: '',
        lastName: '',
        firstName: '',
        middleName: '',
        gender: '',
        semEnrolled: '',
        acadYear: '',
      }),
    };
  },
  methods: {
    openModal(student) {
      $('#edit-student-info-modal').modal('show');
      this.form.fill(student);
    },

    editStudentInfo() {
      let btn = document.getElementById('edit-student-info-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Saving...`;
      btn.disabled = true;

      axios
        .put('api/old-database/special-orders/' + this.form.id, this.form)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          btn.disabled = false;
          btn.innerHTML = 'Save';
          $('#edit-student-info-modal').modal('hide');
          this.$emit('getSO');
        })
        .catch(() => {
          toastr.error('Something went wrong.', 'Error');
          btn.disabled = false;
          btn.innerHTML = 'Save';
        });
    },
  },
};
</script>
