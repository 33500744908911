<template>
  <div>
    <div class="modal" id="edit-special-order-modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Special Order
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="soSearch">
              <div class="form-group ">
                <label for="">Enter SO Number</label>
                <div class="input-group">
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="soParams.level"
                  >
                    <option value="(40)">(40)</option>
                    <option value="(50)">(50)</option>
                    <option value="(80)">(80)</option>
                    <option value="(90)">(90)</option>
                    <option value="(A)">(A)</option>
                    <option value="(B)">(B)</option>
                    <option value="(C)">(C)</option>
                    <option value="(D)">(D)</option>
                    <option value="">Blank</option>
                  </select>
                  <select
                    name=""
                    id=""
                    class="form-control "
                    v-model="soParams.region"
                    required
                  >
                    <option value="" hidden selected disabled
                      >Select Level</option
                    >
                    <option value="(R-XIII)">(R-XIII)</option>
                    <option value="(R-XII)">(R-XII)</option>
                    <option value="(R-XI)">(R-XI)</option>
                    <option value="(R-X)">(R-X)</option>
                    <option value="">Blank</option>
                  </select>
                  <input
                    type="text"
                    class="form-control"
                    v-model="soParams.number"
                    placeholder="Number"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="soParams.sequence"
                    placeholder="Sequence"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-model="soParams.series"
                    placeholder="Series"
                  />
                  <div class="input-group-append">
                    <button
                      id="edit-so-search-button"
                      class="btn btn-outline-secondary text-dark"
                      type="submit"
                      :disabled="
                        soParams.sequence == '' ||
                          soParams.number == '' ||
                          soParams.series == ''
                      "
                    >
                      <i class="bi bi-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <hr class="dotted" />
            <table
              v-if="displayTable"
              class="table table-sm table-bordered text-center"
              style="font-size: 12px;"
            >
              <thead>
                <th>#</th>
                <th>Institution</th>
                <th>Program</th>
                <th>SO Number</th>
                <th>Action</th>
              </thead>
              <tbody>
                <tr v-for="(so, index) in specialOrders" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ so.schoolName }}</td>
                  <td>{{ so.progName }}</td>
                  <td>
                    {{
                      `${so.soLevel}  ${so.soRegion} ${so.soNumber} ${so.soSequence} ${so.soSeries}`
                    }}
                  </td>
                  <td>
                    <a href="#" @click.prevent="fillUpdateForm(so)">Edit</a>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-show="show">
              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Date Issued</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.soDateIssued"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Date of Graduation</label>
                    <input
                      type="text"
                      class="form-control text-uppercase"
                      v-model="form.gradDate"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <label for="">Institution</label>
                  <select
                    name=""
                    id=""
                    class="form-control  text-uppercase"
                    v-model="form.updatedSchoolCode"
                    required
                  >
                    <option value="" selected disabled hidden>Select</option>
                    <option
                      v-for="hei in heis"
                      :key="hei.code"
                      :value="hei.code"
                      >{{ hei.abbrev }} | {{ hei.name }}</option
                    >
                  </select>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Program/Degree</label>
                    <select
                      name=""
                      id=""
                      class="form-control text-uppercase"
                      v-model="form.progName"
                      required
                    >
                      <option :value="form.progName" selected>{{
                        form.progName
                      }}</option>
                      <option
                        v-for="program in programs"
                        :key="program.id"
                        :value="program.program_name"
                        >{{ program.program_name }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Special Order No</label>
                <div class="input-group">
                  <select
                    name=""
                    id=""
                    class="form-control "
                    v-model="form.updatedSoLevel"
                    required
                  >
                    <option value="" hidden selected disabled
                      >Select Level</option
                    >
                    <option value="(40)">(40)</option>
                    <option value="(50)">(50)</option>
                    <option value="(80)">(80)</option>
                    <option value="(90)">(90)</option>
                    <option value="(A)">(A)</option>
                    <option value="(B)">(B)</option>
                    <option value="(C)">(C)</option>
                    <option value="(D)">(D)</option>
                    <option value="">Blank</option>
                  </select>
                  <select
                    name=""
                    id=""
                    class="form-control "
                    v-model="form.updatedSoRegion"
                    required
                  >
                    <option value="" hidden selected disabled
                      >Select Level</option
                    >
                    <option value="(R-XIII)">(R-XIII)</option>
                    <option value="(R-XII)">(R-XII)</option>
                    <option value="(R-XI)">(R-XI)</option>
                    <option value="(R-X)">(R-X)</option>
                    <option value="">Blank</option>
                  </select>
                  <input
                    type="text"
                    class="form-control "
                    v-model="form.updatedSoNumber"
                    placeholder="SO Number"
                    required
                  />

                  <input
                    type="text"
                    class="form-control "
                    v-model="form.updatedSoSequence"
                    placeholder="Sequence"
                    required
                  />
                  <input
                    type="text"
                    class="form-control "
                    v-model="form.updatedSoSeries"
                    placeholder="Series"
                    required
                  />
                </div>
              </div>

              <div class="form-row">
                <div class="col">
                  <div class="form-group">
                    <label for="">Semester & Academic Year Ended</label>
                    <div class="input-group">
                      <select
                        name=""
                        id=""
                        class="form-control  "
                        v-model="form.semStarted"
                        required
                      >
                        <option value="" selected disabled hidden
                          >Select Semester</option
                        >
                        <option value="1ST SEMESTER">1ST SEMESTER</option>
                        <option value="2ND SEMESTER">2ND SEMESTER</option>
                        <option value="3RD SEMESTER">3RD SEMESTER</option>
                        <option value="SUMMER">SUMMER</option>
                        <option value="1ST TRIMESTER">1ST TRIMESTER</option>
                        <option value="2ND TRIMESTER">2ND TRIMESTER</option>
                        <option value="3RD TRIMESTER">3RD TRIMESTER</option>
                      </select>
                      <select
                        name=""
                        id=""
                        class="form-control  "
                        v-model="form.semYear"
                        required
                      >
                        <option value="2022-2023">2022-2023</option>
                        <option value="2021-2022">2021-2022</option>
                        <option value="2020-2021">2020-2021</option>
                        <option value="2019-2020">2019-2020</option>
                        <option value="2018-2019">2018-2019</option>
                        <option value="2017-2018">2017-2018</option>
                        <option value="2016-2017">2016-2017</option>
                        <option value="2015-2016">2015-2016</option>
                        <option value="2014-2015">2014-2015</option>
                        <option value="2013-2014">2013-2014</option>
                        <option value="2012-2013">2012-2013</option>
                        <option value="2011-2012">2011-2012</option>
                        <option value="2010-2011">2010-2011</option>
                        <option value="2009-2010">2009-2010</option>
                        <option value="2008-2009">2008-2009</option>
                        <option value="2007-2008">2007-2008</option>
                        <option value="2006-2007">2006-2007</option>
                        <option value="2005-2006">2005-2006</option>
                        <option value="2004-2005">2004-2005</option>
                        <option value="2003-2004">2003-2004</option>
                        <option value="2002-2003">2002-2003</option>
                        <option value="2001-2002">2001-2002</option>
                        <option value="2000-2001">2000-2001</option>
                        <option value="1999-2000">1999-2000</option>
                        <option value="1998-1999">1998-1999</option>
                        <option value="1997-1998">1997-1998</option>
                        <option value="1996-1997">1996-1997</option>
                        <option value="1995-1996">1995-1996</option>
                        <option value="1994-1995">1994-1995</option>
                        <option value="1993-1994">1993-1994</option>
                        <option value="1992-1993">1992-1993</option>
                        <option value="1991-1992">1991-1992</option>
                        <option value="1990-1991">1990-1991</option>
                        <option value="1989-1990">1989-1990</option>
                        <option value="1988-1989">1988-1989</option>
                        <option value="1987-1988">1987-1988</option>
                        <option value="1986-1987">1986-1987</option>
                        <option value="1985-1986">1985-1986</option>
                        <option value="1984-1985">1984-1985</option>
                        <option value="1983-1984">1983-1984</option>
                        <option value="1982-1983">1982-1983</option>
                        <option value="1981-1982">1981-1982</option>
                        <option value="1980-1981">1980-1981</option>
                        <option value="1979-1980">1979-1980</option>
                        <option value="1978-1979">1978-1979</option>
                        <option value="1977-1978">1977-1978</option>
                        <option value="1976-1977">1976-1977</option>
                        <option value="1975-1976">1975-1976</option>
                        <option value="1974-1975">1974-1975</option>
                        <option value="1973-1974">1973-1974</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="">Signed by</label>
                    <select
                      name=""
                      id=""
                      class="form-control"
                      v-model="form.soSignedBy"
                      required
                    >
                      <option value="DR. GEORGE M. COLORADO, CESO III" selected
                        >DR. GEORGE M. COLORADO, CESO III</option
                      >

                      <option value="LEONIDA S. CALAGUI, PH.D., CESO III"
                        >LEONIDA S. CALAGUI, PH.D., CESO III</option
                      >
                      <option value="MARICAR R. CASQUEJO. Ph.D., CESO III"
                        >MARICAR R. CASQUEJO. Ph.D., CESO III</option
                      >
                      <option value="N/A">N/A</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="form.path">
                <label for="">Uploaded File</label>
                <div>
                  <a href="#" @click.prevent="$emit('openUrl', form.path)">
                    {{ form.filename }}
                  </a>
                </div>

                <small class="form-text text-muted"
                  >Uploaded on: {{ form.uploaded_at | standardDate }}</small
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              CLOSE
            </button>
            <button
              id="edit-so-btn"
              class="btn btn-primary"
              :disabled="!show"
              @click="updateSpecialOrder"
            >
              UPDATE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditSpecialOrderModal',
  data() {
    return {
      loader: false,
      show: false,
      displayTable: false,
      heis: [],
      specialOrders: [],
      programs: [],
      soParams: {
        level: '(50)',
        region: '(R-XIII)',
        number: '',
        sequence: '',
        series: '',
      },
      form: new Form({
        id: '',
        soDateIssued: '',
        schoolCode: '',
        updatedSchoolCode: '',
        progName: '',
        semStarted: '',
        semYear: '',
        soLevel: '',
        updatedSoLevel: '',
        soRegion: '',
        updatedSoRegion: '',
        gradDate: '',
        soSeries: '',
        updatedSoSeries: '',
        soNumber: '',
        updatedSoNumber: '',
        soSequence: '',
        updatedSoSequence: '',
        soSignedBy: 'DR. GEORGE M. COLORADO, CESO III',
        filename: '',
        path: '',
        uploaded_at: '',
        file: '',
      }),
    };
  },
  methods: {
    openModal() {
      $('#edit-special-order-modal').modal('show');
      this.show = false;
      this.form.reset();
      this.getHeis();
      this.getPrograms();
    },
    fillUpdateForm(so) {
      this.form.fill(so);
      this.form.updatedSchoolCode = this.form.schoolCode;
      this.form.updatedSoLevel = this.form.soLevel;
      this.form.updatedSoRegion = this.form.soRegion;
      this.form.updatedSoNumber = this.form.soNumber;
      this.form.updatedSoSequence = this.form.soSequence;
      this.form.updatedSoSeries = this.form.soSeries;

      // format previous so entries that does not follow the date input format
      // ex. JUNE 30, 2016 -> 2016-06-30
      // added on 06/30/2022
      this.form.soDateIssued = moment(so.soDateIssued).format('YYYY-MM-DD');
      this.show = true;
      this.displayTable = false;
    },
    updateSpecialOrder() {
      let btn = document.getElementById('edit-so-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> UPDATING...`;
      btn.disabled = true;

      axios
        .put(
          'api/old-database/special-orders/update-so/' + this.form.id,
          this.form
        )
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'UPDATE';
          toastr.success(res.data.msg, 'Information');
          $('#edit-special-order-modal').modal('hide');
          this.$emit('getSO');
        })
        .catch(() => {
          btn.disabled = false;
          btn.innerHTML = 'UPDATE';
          toastr.error('Something went wrong!', 'Error');
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrograms() {
      axios
        .get('api/programs')
        .then((response) => {
          this.programs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFile(event) {
      this.form.file = event.target.files[0];
    },

    soSearch() {
      let loader = document.getElementById('edit-so-search-button');
      loader.disabled = true;
      loader.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .post('api/old-database/special-orders/search-by-number', this.soParams)
        .then((res) => {
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
          this.specialOrders = res.data;
          this.displayTable = true;
        })
        .catch((err) => {
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
          toastr.error('Something went wrong.', 'Error');
        });
    },
  },
};
</script>
