<template>
  <div>
    <div class="d-flex justify-content-between ">
      <h5 class="font-weight-bold my-auto">
        Special Orders (OLD)
      </h5>
      <div class="">
        <button class="btn btn-primary" @click="openModal">
          ENCODE SPECIAL ORDER
        </button>

        <button class="btn btn-link" @click="openEditSpecialOrderModal">
          EDIT
        </button>
      </div>
    </div>
    <div class="card rounded shadow-sm mt-4">
      <div class="card-body">
        <form
          @submit.prevent="
            getSpecialOrders(DEFAULT_URL, {
              loader: { enabled: false },
              searchLoader: { enabled: true },
            })
          "
        >
          <div class="form-row">
            <div class="col-6 ">
              <label for="">Search by Name</label>
              <div class="input-group ">
                <input
                  v-model="params.lastName"
                  type="search"
                  class="form-control"
                  placeholder="Last Name"
                />

                <input
                  type="search"
                  v-model="params.firstName"
                  class="form-control"
                  placeholder="First Name"
                />

                <input
                  v-model="params.middleName"
                  type="search"
                  class="form-control"
                  placeholder="Middle Name"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="">SO Number</label>
                <div class="input-group">
                  <select
                    name=""
                    id=""
                    class="form-control"
                    v-model="params.level_code"
                  >
                    <option value="">Blank</option>
                    <option value="(40)">(40)</option>
                    <option value="(50)">(50)</option>
                    <option value="(80)">(80)</option>
                    <option value="(90)">(90)</option>
                    <option value="(A)">(A)</option>
                    <option value="(B)">(B)</option>
                    <option value="(C)">(C)</option>
                    <option value="(D)">(D)</option>
                  </select>
                  <select
                    name=""
                    id=""
                    class="form-control "
                    v-model="params.region"
                    required
                  >
                    <option value="(R-XIII)">(R-XIII)</option>
                    <option value="(R-XII)">(R-XII)</option>
                    <option value="(R-XI)">(R-XI)</option>
                    <option value="(R-X)">(R-X)</option>
                    <option value="">Blank</option>
                  </select>
                  <input
                    type="search"
                    class="form-control"
                    v-model="params.number"
                    placeholder="Number"
                  />
                  <input
                    type="search"
                    class="form-control"
                    v-model="params.sequence"
                    placeholder="Sequence"
                  />
                  <input
                    type="search"
                    class="form-control"
                    v-model="params.series"
                    placeholder="Series"
                  />
                </div>
              </div>
            </div>
            <div class="col-1">
              <div class="form-group">
                <label for="" class="invisible">Button</label>
                <button
                  type="submit"
                  class="form-control btn btn-primary"
                  style="background-color:#321fdb; color: #fff"
                >
                  <span v-if="!searchLoader">SEARCH</span>
                  <span v-else class="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control form-control-sm ml-2"
                v-model="perPage"
                @change="
                  getSpecialOrders(DEFAULT_URL, {
                    loader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-right align-items-baseline">
            <div class="my-auto mr-2">
              <span
                >{{
                  `${pagination.from || 0}-${pagination.to ||
                    0} of ${pagination.total || 0}`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0 pagination-sm">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getSpecialOrders(pagination.prev_page_url, {
                        loader: { enabled: false },
                        searchLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getSpecialOrders(pagination.next_page_url, {
                        loader: { enabled: false },
                        searchLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <table
          class="table table-sm table-bordered table-hover"
          style="font-size: 12.5px;"
        >
          <thead class="text-center">
            <th>#</th>
            <th>NAME</th>
            <th width="20%">INSTITUTION</th>
            <th width="20%">PROGRAM</th>
            <th>DATE STARTED</th>
            <th>DATE ENDED</th>
            <th>GRAD. DATE</th>
            <th>DATE ISSUED</th>
            <th>ENCODED BY</th>
            <th>ACTION</th>
          </thead>
          <tbody>
            <tr v-show="tableLoader">
              <td colspan="13" class="text-center">
                <div class="spinner-border spinner-border-sm mr-2"></div>
                Loading...
              </td>
            </tr>
            <tr
              v-if="!specialOrders.length && !tableLoader"
              class="text-center"
            >
              <td colspan="13">No results found.</td>
            </tr>
            <tr v-for="so in specialOrders" :key="so.id" class="text-uppercase">
              <th>{{ so.id }}</th>
              <td width="17%" class="">
                {{ so.firstName }} {{ so.middleName }} {{ so.lastName }}
                <span class="form-text" v-if="so.path">
                  SO:
                  <a href="#" @click.prevent="openUrl(so.path)">
                    {{ so.soLevel }} {{ so.soRegion }} {{ so.soNumber }}
                    {{ so.soSequence }} {{ so.soSeries }}</a
                  >
                </span>
                <span v-else class="form-text"
                  >SO: ({{ so.soLevel }}) (R-XIII) No. {{ so.soNumber }}
                  {{ so.soSequence }} s. {{ so.soSeries }}</span
                >
              </td>
              <td>{{ so.schoolName }}</td>
              <td>
                {{ so.progName }}
                <span v-if="so.major">- {{ so.major }}</span>
              </td>

              <td>
                {{ so.semEnrolled }}
                <span class="form-text">AY: {{ so.acadYear }}</span>
              </td>
              <td>
                {{ so.semStarted }}
                <span class="form-text">AY: {{ so.semYear }}</span>
              </td>
              <td>{{ so.gradDate }}</td>

              <td>
                <span v-show="so.soDateIssued">{{
                  so.soDateIssued | shortDate
                }}</span>
              </td>
              <td>
                <span v-show="so.uploaded_at"
                  >{{ so.updateby }}
                  <span class="form-text">{{
                    so.uploaded_at | shortDate
                  }}</span></span
                >
              </td>
              <td>
                <div class="btn-group">
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    @click="openEditStudentInfoModal(so)"
                  >
                    <i class="bi bi-pencil text-dark"></i>
                  </button>
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    @click="deleteSpecialOrder(so.id)"
                  >
                    <i class="bi bi-trash text-danger "></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EncodeSpecialOrder
      ref="EncodeSpecialOrder"
      v-on:getSpecialOrders="
        getSpecialOrders(DEFAULT_URL, {
          loader: { enabled: false },
          searchLoader: { enabled: false },
        })
      "
    ></EncodeSpecialOrder>
    <EditStudentInfoModal
      ref="EditStudentInfoModal"
      v-on:getSO="
        getSpecialOrders(DEFAULT_URL, {
          loader: { enabled: false },
          searchLoader: { enabled: false },
        })
      "
    ></EditStudentInfoModal>

    <EditSpecialOrderModal
      ref="EditSpecialOrderModal"
      v-on:getSO="
        getSpecialOrders(DEFAULT_URL, {
          loader: { enabled: false },
          searchLoader: { enabled: false },
        })
      "
      v-on:openUrl="openUrl"
    ></EditSpecialOrderModal>
  </div>
</template>

<script>
import EncodeSpecialOrder from './components/EncodeSpecialOrder.vue';
import EditStudentInfoModal from './components/EditStudentInfoModal.vue';
import EditSpecialOrderModal from './components/EditSpecialOrderModal.vue';

export default {
  name: 'SpecialOrdersOld',
  components: {
    EncodeSpecialOrder,
    EditStudentInfoModal,
    EditSpecialOrderModal,
  },
  data() {
    return {
      tableLoader: false,
      searchLoader: false,
      specialOrders: [],
      prefix: 'https://drive.google.com/file/d/',
      searchValue: '',
      params: {
        firstName: '',
        lastName: '',
        middleName: '',
        level_code: '',
        region: '',
        number: '',
        sequence: '',
        series: '',
      },
      pagination: {},
      perPage: 10,
      DEFAULT_URL: '/api/old-database/special-orders',
    };
  },
  methods: {
    openModal() {
      this.$refs.EncodeSpecialOrder.openModal();
    },
    getSpecialOrders(page_url, options) {
      this.tableLoader = options.loader.enabled;
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: {
            firstName: this.params.firstName,
            lastName: this.params.lastName,
            middleName: this.params.middleName,
            soLevel: this.params.level_code,
            soRegion: this.params.region,
            soNumber: this.params.number,
            soSequence: this.params.sequence,
            soSeries: this.params.series,
            perPage: this.perPage,
          },
        })
        .then((res) => {
          this.specialOrders = res.data.data;
          this.makePagination(res.data);
          this.tableLoader = false;
          this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
        });
    },
    openEditStudentInfoModal(so) {
      this.$refs.EditStudentInfoModal.openModal(so);
    },
    openEditSpecialOrderModal() {
      this.$refs.EditSpecialOrderModal.openModal();
    },
    deleteSpecialOrder(id) {
      if (confirm('Are you sure you want to delete selected item? ID: ' + id)) {
        axios
          .delete('api/old-database/special-orders/' + id)
          .then((res) => {
            toastr.success(res.data.msg, 'Information');
            this.getSpecialOrders(this.DEFAULT_URL, {
              loader: { enabled: false },
              searchLoader: { enabled: false },
            });
          })
          .catch((err) => {
            toastr.error(err.response.data.message, 'Error');
          });
      }
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },

    searchSpecialOrder() {
      let loader = document.getElementById('name-search-button');
      loader.disabled = true;
      loader.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .post(
          'api/old-database/special-orders/search-record',
          this.searchParams
        )
        .then((res) => {
          this.specialOrders = res.data;
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
        });
    },

    soSearch() {
      let loader = document.getElementById('so-search-button');
      loader.disabled = true;
      loader.innerHTML = `<span class="spinner-border spinner-border-sm"></span>`;

      axios
        .post('api/old-database/special-orders/filter-by-number', this.soParams)
        .then((res) => {
          this.specialOrders = res.data;
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
        })
        .catch((err) => {
          loader.disabled = false;
          loader.innerHTML = '<i class="bi bi-search"></i>';
          toastr.error('Something went wrong.', 'Error');
        });
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
  },

  mounted() {
    this.getSpecialOrders(null, {
      loader: { enabled: true },
      searchLoader: { enabled: false },
    });
  },
};
</script>
